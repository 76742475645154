import React from "react";
// import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {palette} from '../assets/Palette';

class HelpEn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        document.body.classList.add("white-content");
    }

    generateDocumentation() {
        let doc = []
        let source = process.env.REACT_APP_SOURCE
        
        doc.push(<h3 key='h3'>Help on search API</h3>)
        switch (source){
            case 'schwarz':
                doc.push(<a key='doc' href='http://schwarz-contentaggregation-test.bayard-consulting.com/schwarz-docs/' target='_blank' rel="noopener noreferrer">Techinical documentation search-API</a>)
                break;
            case 'radeberger':
                doc.push(<a key='doc' href='http://radeberger-contentaggregation-test.bayard-consulting.com/radeberger-docs/' target='_blank' rel="noopener noreferrer">Techinical documentation search-API</a>)
                break;
            case 'aldi':
                doc.push(<a key='doc' href='http://aldi-contentaggregation-test.bayard-consulting.com/aldi-docs/' target='_blank' rel="noopener noreferrer">Techinical documentation search-API</a>)
                break;
            case 'denner':
                doc.push(<a key='doc' href='http://denner-contentaggregation-test.bayard-consulting.com/denner-docs/' target='_blank' rel="noopener noreferrer">Techinical documentation search-API</a>)
                break;
            case 'bc':
                doc.push(<a key='doc' href='http://productcontent-test.bayard-consulting.com/aldi-docs/' target='_blank' rel="noopener noreferrer">Techinical documentation search-API</a>)
                break;
            default:
                break;
        }
        return doc;
    }

    render() {
        return (
            <>
                <div className="content">
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%' }}>
                                    <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" width='192' height='53' />
                                </div>
                            </CardHeader>
                            <CardBody style={{ marginTop: '2%' }}>
                                <div style={{ fontSize: '1rem' }}>
                                    <h2 style={{ color: palette.text.dark }}>Search Guide</h2>
                                    <h3 style={{ color: palette.text.dark }}>General information</h3>
                                    <p style={{ color: palette.text.main }}>
                                    The search is triggered by entering a single search term or several terms separated by spaces in the search field.<br />
                                    The search considers all attributes of the available articles in the search index. This means that it is possible that the articles are listed in the hit list, but the search term is not directly visible there.<br /><br />
                                    </p>
                                    <h4 style={{ color: palette.text.dark }}>Settings:</h4>
                                    Various search settings can be configured:<br /><br />
                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>Items per Page</b><br />Specifies how many entries are displayed in the search hit list. </li><br />
                                        <li style={{ color: palette.text.main }}><b>Logical Operator</b><br /><b>AND - </b>the search terms entered must ALL be found in a single article.<br />Entering the search terms "fish meat" with this option will only find articles which contain the term "fish" AND the term "meat" in descriptive attributes.<br /><b>OR - </b>AT LEAST ONE of the search terms must be found in a single article.<br />Entering the search terms "fish meat" with this option will find articles containing either the term "fish" OR the term "meat" OR both terms in descriptive attributes.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Search for Exact Terms</b><br />If this option is active, the search terms will only be found in the exact spelling, otherwise different spellings will also be found.<br />Example: With the option disabled, searching with term "yoghurt" also considers articles with the spelling "yoghurt" or "yogurt".</li><br />
                                        <li style={{ color: palette.text.main }}><b>Search in</b><br />Spefifies where the data comes from, currently only 'GDSN' is possible.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Search Scope</b><br />The data set includes not only consumer items but also the packaging items, such as cartons or pallets. With the option "Only Consumer Unit" active, the packaging is excluded from the search result.</li>
                                    </ul><br />
                                    <h3 style={{ color: palette.text.dark }}>Use of attribute identifiers as search filters</h3>
                                    <p style={{ color: palette.text.main }}>
                                    Example: 'brandName:searchTerm' term restricts the search of "searchTerm" to the attribute "brand name", e.g. "brandName:Fanta" only finds articles that contain the term "Fanta" in the brand name.<br /><br />
                                    With the help of attribute identifiers of the search index, searches can be restricted to specific attributes. The attribute identifier is placed in front of the search term as a prefix separated by a colon. Please pay attention to the exact spelling (upper/lower case) of the attribute identifier.<br /><br /></p>
                                    <b style={{ color: palette.text.main }}>List of possible search filters:</b><br /><br />
                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>source:</b> (Data source, at the moment only "GDSN" possible)</li>
                                        <li style={{ color: palette.text.main }}><b>productName:</b> (Article description)</li>
                                        <li style={{ color: palette.text.main }}><b>productDescription:</b> (Article description)</li>
                                        <li style={{ color: palette.text.main }}><b>brandName:</b> (Brand name)</li>
                                        <li style={{ color: palette.text.main }}><b>manufacturer:</b> (Manufacturer/Supplier)</li>
                                        <li style={{ color: palette.text.main }}><b>targetMarket:</b> (Target market)</li>
                                        <li style={{ color: palette.text.main }}><b>gtin:</b> (GTIN of an article)</li>
                                        <li style={{ color: palette.text.main }}><b>gln:</b> (GLN of a manufacturer)</li>
                                        <li style={{ color: palette.text.main }}><b>gpc:</b> (GPC Code Block, Class family or Segment)</li>
                                    </ul>
                                    <p style={{ color: palette.text.main }}>
                                        You can also use the full path an exact attribute, according to the GDSN item format<br/>
                                        E.g. <b>tradeItemInformation.marketingInformationModule.marketingInformation.targetConsumer.targetConsumerGender:</b>FEMALE<br />
                                        <br/>{this.generateDocumentation()}
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </>
        );
    }
}
export default HelpEn
