
import React from "react";
import Modal from 'react-modal';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CustomerContext } from "../../common/CustomerContext.js";
import Moment from 'react-moment';
import CreateGdsnSubscription from './CreateGdsnSubscription';
import { withTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ErrorList from "../modals/components/ErrorList.jsx";
import {getCountryNameWithCode} from '../../common/helpers/CountryCodeHelper';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchFilter from "./SearchFilter.jsx";

class GdsnSubscriptionModal extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            // filter
            activeSubscriptionFilter: 'show-all',
            // table options
            rowsPerPage: 10,
            page: 0, // zero-based
            isSnackbarOpen: false,
            // (delete/resubscribe)action
            isActionRequestSuccessful: false,
            actionRequestErrors: [],
            validSubscriberGlns: [],
            valueTab: 0,
            activitiesData: [],
            activitiesTotal: 0,
            activitiesRowsPerPage: 10,
            activitiesPage: 0,
            targetMarketSearch: '',
            gtinSearch: '',
            glnSearch: '',
            gpcSearch: '',
            validTargetMarkets: [],
            filterChange: false,
            isSearching: false
        }

        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleActivitiesChangeRowsPerPage = this.handleActivitiesChangeRowsPerPage.bind(this);
        this.handleActivitiesChangePage = this.handleActivitiesChangePage.bind(this);
        this.handleTargetMarketSearch = this.handleTargetMarketSearch.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleRefreshPage = this.handleRefreshPage.bind(this);
        this.viewButtonOnClick = this.viewButtonOnClick.bind(this);
        this.deleteButtonOnClick = this.deleteButtonOnClick.bind(this);
        this.resubscribeButtonOnClick = this.resubscribeButtonOnClick.bind(this);
        this.toggleSnackbar = this.toggleSnackbar.bind(this);
        this.toggleShowOnlyActiveSubscriptions = this.toggleShowOnlyActiveSubscriptions.bind(this);
        this.getActivities = this.getActivities.bind(this)
        this.getSubscriptions = this.getSubscriptions.bind(this)
        this.getActivitiesWithFilter = this.getActivitiesWithFilter.bind(this)
        this.getSubscriptionsWithFilter = this.getSubscriptionsWithFilter.bind(this)
    }

    componentDidMount() {
        this.getSubscriptions();
        this.getValidTargetMarkets();
        this.getActivities();
        this.getValidSubscriberGlns();
    }

    createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForGdsnSubscriptions()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    getSubscriptions(useFilters = false) {
        this.context.apiService
            .getGdsnSubscriptions(useFilters, this.state.glnSearch, this.state.gtinSearch, this.state.targetMarketSearch, this.state.gpcSearch, this.state.page + 1, this.state.rowsPerPage)
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ data: res.data, total: res.total, isSearching: false });
                    });
                } else {
                    res.json().then(res => {
                        console.log("Error in fetching gdsn subscriptions");
                        console.log(res);
                        this.setState({ isSearching: false });
                    })
                }
            });
    }

    getActivities(useFilters = false) {
        this.context.apiService
            .getGdsnSubscriptionActivities(useFilters, this.state.glnSearch, this.state.gtinSearch, this.state.targetMarketSearch, this.state.gpcSearch, this.state.activitiesPage + 1, this.state.activitiesRowsPerPage)
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ activitiesData: res.data, activitiesTotal: res.total });
                    });
                } else {
                    res.json().then(res => {
                        console.log("Error in fetching gdsn subscription activities");
                        console.log(res);
                    })
                }
            });
    }

    getSubscriptionsWithFilter() {
        this.setState({
            page: 0
        }, () => this.getSubscriptions(true));
    }

    getActivitiesWithFilter() {
        this.setState({
            activitiesPage: 0
        }, () => this.getActivities(true));
    }

    getValidSubscriberGlns() {
        this.context.apiService.getValidSubscriberGlns()
            .then(res => res.json())
            .then(res => {
                this.setState({ validSubscriberGlns: res.data });
            });
    }

    renderNullText() {
        return <i>null</i>;
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        },
            this.getSubscriptions);

    }

    handleChangePage(event, newPage) {
        this.setState({
            page: newPage
        }, this.getSubscriptions);
    }

    handleRefreshPage() {
        this.getSubscriptions();
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            targetMarketSearch: '',
            gtinSearch: '',
            glnSearch: '',
            gpcSearch: '',
            page: 0,
            activitiesPage: 0,
            valueTab: newValue
        }, () => {
            if(newValue === 0) this.getSubscriptions();
            else this.getActivities();
        });
    };

    handleActivitiesChangeRowsPerPage(event) {
        this.setState({
            activitiesRowsPerPage: parseInt(event.target.value, 10),
            activitiesPage: 0
        }, this.getActivities);

    }

    handleActivitiesChangePage(event, newPage) {
        this.setState({
            activitiesPage: newPage
        }, this.getActivities);
    }

    viewButtonOnClick(subscription) {
        console.log(subscription);
        let query = "";
        query += (subscription.subscriberGln) ? "subscriberGln:" + subscription.subscriberGln : "";
        query += (subscription.gln) ? " informationProviderOfTradeItem.gln:" + subscription.gln : "";
        query += (subscription.gpc) ? " gdsnTradeItemClassification.gpcCategoryCode:" + subscription.gpc : "";
        query += (subscription.gtin) ? " gtin:" + subscription.gtin : "";
        query += (subscription.targetMarket) ? " targetMarket.targetMarketCountryCode:" + subscription.targetMarket : "";

        this.props.toggleGdsnSubscriptionModal();
        this.props.specifyQuery(query);
    }

    deleteButtonOnClick(subscription) {
        this.setState({ isSnackbarOpen: false });
        this.context.apiService.deleteGdsnSubscription(subscription)
            .then(res => {
                console.log(res);
                if (res.ok) {
                    this.setState({
                        isActionRequestSuccessful: true,
                        isSnackbarOpen: true
                    });
                    this.handleRefreshPage()

                } else {
                    res.json().then(res => {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isActionRequestSuccessful: false,
                            isSnackbarOpen: true,
                            actionRequestErrors: res.errors
                        });
                    });

                }
            });
    }

    resubscribeButtonOnClick(subscription) {
        this.setState({ isSnackbarOpen: false });
        this.context.apiService.createGdsnSubscription(subscription)
            .then(res => {
                console.log(res);
                if (res.ok) {
                    this.setState({
                        isActionRequestSuccessful: true,
                        isSnackbarOpen: true
                    });
                    this.handleRefreshPage()

                } else {
                    res.json().then(res => {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isActionRequestSuccessful: false,
                            isSnackbarOpen: true,
                            actionRequestErrors: res.errors
                        });
                    });

                }
            });
    }

    toggleShowOnlyActiveSubscriptions(event) {
        this.setState({
            activeSubscriptionFilter: event.target.value
        }, this.getSubscriptions);
    }

    toggleSnackbar() {
        this.setState({
            isSnackbarOpen: !this.state.isSnackbarOpen
        });
    }

    a11yProps(indexTab) {
        return {
          id: `nav-tab-${indexTab}`,
          'aria-controls': `nav-tabpanel-${indexTab}`,
        };
    }

    handleTargetMarketSearch(event) {
        this.setState({
            targetMarketSearch: event.target.value
        })
    }

    handleOnChange(event) {
        let fieldId = event.target.id;
        let value = event.target.value;
        this.setState({
            [fieldId]: value
        });
        console.log(fieldId)
        console.log(value)
    }

    render() {
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.props.isGdsnSubscriptionModalDisplayed}
                toggle={this.props.toggleGdsnSubscriptionModal}
                ariaHideApp={false}
            >
                <Tooltip title={t('gdsnSubscriptionModal.close')}>
                    <IconButton
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.props.toggleGdsnSubscriptionModal}
                        style={{ outline: 'none' }}
                        size="large">
                        <CloseRoundedIcon style={{ color: palette.warning.close }} />
                    </IconButton>
                </Tooltip>

                <h2>GDSN Subscription</h2>
                <CreateGdsnSubscription handleRefreshPage={this.handleRefreshPage} />
                <hr />
                <AppBar position="static">
                <Tabs 
                    value={this.state.valueTab} 
                    onChange={this.handleTabChange}
                    textColor="inherit"
                >
                    <Tab label={t('gdsnSubscriptionModal.subscriptions')} {...this.a11yProps(0)} />
                    <Tab label={t('gdsnSubscriptionModal.activityLogs')} {...this.a11yProps(1)} />
                </Tabs>
                </AppBar>
                <TabPanel value={this.state.valueTab} index={0}>
                    <SearchFilter isSearching = {this.state.isSearching} handleTargetMarketSearch = {this.handleTargetMarketSearch} targetMarketSearch = {this.state.targetMarketSearch} gtinSearch = {this.state.gtinSearch} glnSearch = {this.state.glnSearch} gpcSearch = {this.state.gpcSearch} validTargetMarkets = {this.state.validTargetMarkets}  submitFunction = {this.getSubscriptionsWithFilter} handleOnChange = {this.handleOnChange}/>
                    <div style={{ paddingTop: '1em' }}>
                        <Paper elevation={2}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{t('gdsnSubscriptionModal.subscriberGln')}</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.active')}</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.targetMarket')}</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.supplierGln')}</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.supplierGtin')}</TableCell>
                                        <TableCell align="center">GPC</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.updatedAt')}</TableCell>
                                        <TableCell align="center">{t('gdsnSubscriptionModal.action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell component="th" scope="row">
                                                {item.subscriberGln || this.state.validSubscriberGlns[0]}
                                            </TableCell>
                                            <TableCell align="center">{item.isDeleted ? 'false' : 'true'}</TableCell>
                                            <TableCell align="center">{getCountryNameWithCode(item.targetMarket) || this.renderNullText()}</TableCell>
                                            <TableCell align="center">{item.gln || this.renderNullText()}</TableCell>
                                            <TableCell align="center">{item.gtin || this.renderNullText()}</TableCell>
                                            <TableCell align="center">{item.gpc || this.renderNullText()}</TableCell>
                                            <TableCell align="center"><Moment local>{item.updatedAt}</Moment></TableCell>
                                            <TableCell align="center">
                                                {!item.isDeleted ?
                                                    <Button
                                                        style={ {color: palette.error.main, borderColor: palette.error.main}}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={this.deleteButtonOnClick.bind(this, item)}>{t('gdsnSubscriptionModal.delete')}</Button>
                                                    : <Button
                                                        style={ process.env.REACT_APP_SOURCE === 'schwarz' ? { color: "#3f51b5", borderColor: "#3f51b5" } : {color: "#002857", borderColor: "#002857"} }
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={this.resubscribeButtonOnClick.bind(this, item)}>{t('gdsnSubscriptionModal.resubscribe')}</Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.total}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </TabPanel>
                <TabPanel value={this.state.valueTab} index={1}>
                <SearchFilter isSearching = {this.state.isSearching} handleTargetMarketSearch = {this.handleTargetMarketSearch} targetMarketSearch = {this.state.targetMarketSearch} gtinSearch = {this.state.gtinSearch} glnSearch = {this.state.glnSearch} gpcSearch = {this.state.gpcSearch} validTargetMarkets = {this.state.validTargetMarkets}  submitFunction = {this.getActivitiesWithFilter} handleOnChange = {this.handleOnChange}/>
                    <div style={{ paddingTop: '1em' }}>
                        <Paper elevation={2}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{t('gdsnSubscriptionModal.subscriberGln')}</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.targetMarket')}</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.supplierGln')}</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.supplierGtin')}</TableCell>
                                        <TableCell align="right">GPC</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.createdAt')}</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.updatedAt')}</TableCell>
                                        <TableCell align="right">{t('gdsnSubscriptionModal.action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.activitiesData.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell component="th" scope="row">
                                                {item.subscriberGln || this.state.validSubscriberGlns[0]}
                                            </TableCell>
                                            <TableCell align="right">{getCountryNameWithCode(item.targetMarket) || this.renderNullText()}</TableCell>
                                            <TableCell align="right">{item.gln || this.renderNullText()}</TableCell>
                                            <TableCell align="right">{item.gtin || this.renderNullText()}</TableCell>
                                            <TableCell align="right">{item.gpc || this.renderNullText()}</TableCell>
                                            <TableCell align="right"><Moment local>{item.createdAt}</Moment></TableCell>
                                            <TableCell align="right"><Moment local>{item.updatedAt}</Moment></TableCell>
                                            <TableCell align="right">{item.commandType || this.renderNullText()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.activitiesTotal}
                                rowsPerPage={this.state.activitiesRowsPerPage}
                                page={this.state.activitiesPage}
                                onPageChange={this.handleActivitiesChangePage}
                                onRowsPerPageChange={this.handleActivitiesChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </TabPanel>
                <Snackbar open={this.state.isSnackbarOpen} autoHideDuration={20000}
                    onClose={this.toggleSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    {this.state.isActionRequestSuccessful ?
                        <Alert onClose={this.toggleSnackbar} severity="success">
                            <b>{t('gdsnSubscriptionModal.gdsnSubscriptionRequestSuccess')}</b>
                            <br />
                            {t('gdsnSubscriptionModal.gdsnSubscriptionStateHelpText')}
                        </Alert>
                        : <ErrorList onClose={this.toggleSnackbar} errors={this.state.actionRequestErrors} />
                    }
                </Snackbar>
            </Modal>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
export default withTranslation()(GdsnSubscriptionModal);