/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";


import { CustomerContext } from "./common/CustomerContext.js";
import MigrosAPIService from "./common/services/api/MigrosAPIService";
import RadebergerAPIService from "./common/services/api/RadebergerAPIService.js";
import FlaschenpostAPIService from "./common/services/api/FlaschenpostAPIService.js";
import SchwarzAPIService from "./common/services/api/SchwarzAPIService.js";
import SchwarzMvpAPIService from "./common/services/api/SchwarzMvpAPIService.js";
import BCAPIService from "./common/services/api/BCAPIService.js";

import "./assets/scss/black-dashboard-react.scss";
import "./assets/scss/trade-item-view.component.scss";
import MigrosViewRenderer from "./common/renderers/MigrosViewRenderer";
import SchwarzViewRenderer from "./common/renderers/SchwarzViewRenderer";
import SchwarzMvpViewRenderer from "./common/renderers/SchwarzMvpViewRenderer";
import RadebergerViewRenderer from "./common/renderers/RadebergerViewRenderer";
import FlaschenpostViewRenderer from "./common/renderers/FlaschenpostViewRenderer";
import BCViewRenderer from "./common/renderers/BCViewRenderer";

import 'moment-timezone';
import './i18n';
import CircularProgress from '@mui/material/CircularProgress';

import DennerAPIService from "./common/services/api/DennerAPIService";
import DennerViewRenderer from "./common/renderers/DennerViewRenderer";
import AldiAPIService from "./common/services/api/AldiAPIService";
import MarkantBookAPIService from "./common/services/api/MarkantBookAPIService";
import AldiViewRenderer from "./common/renderers/AldiViewRenderer";
import MarkantBookViewRenderer from "./common/renderers/MarkantBookViewRenderer";
import {LoginRoute, HelpEnRoute, HelpRoute, ContentProviderRoute, AcsRoute, defaultPath} from "./common/Middleware"
import { LocalizationProvider } from '@mui/x-date-pickers';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const source = process.env.REACT_APP_SOURCE;

function getCurrentContext() {
  let apiService;
  let viewRenderer;
  let isSubscriptionEnabled = false;
  let isEuvinoSubscriptionEnabled = false;
  let isBasicAuthEnabled = true;
  let isCicEnabled = false;
  let isGdsnTradeItemEnabled = false;
  let isExportEnabled = false;
  let isChildGtinSwitchEnabled = false;
  let isTradeItemHierarchyModalEnabled = false;

  switch (source) {
    case 'migros':
      apiService = new MigrosAPIService();
      viewRenderer = new MigrosViewRenderer();
      break;
    case 'schwarz':
      if (['mvp', 'lidl'].includes(process.env.REACT_APP_MODE)){
        apiService = new SchwarzMvpAPIService();
        viewRenderer = new SchwarzMvpViewRenderer();
      } else {
        apiService = new SchwarzAPIService();
        viewRenderer = new SchwarzViewRenderer();
      }
      if (['prod', 'mvp', 'lidl'].includes(process.env.REACT_APP_MODE)) {
        isExportEnabled = true;
      }
      break;
    case 'radeberger':
      apiService = new RadebergerAPIService();
      viewRenderer = new RadebergerViewRenderer();
      isSubscriptionEnabled = true;
      isEuvinoSubscriptionEnabled = true;
      isChildGtinSwitchEnabled = true;
      isExportEnabled = true;
      break;
    case 'flaschenpost':
        apiService = new FlaschenpostAPIService();
        viewRenderer = new FlaschenpostViewRenderer();
        isSubscriptionEnabled = true;
        isEuvinoSubscriptionEnabled = true;
        isChildGtinSwitchEnabled = true;
        isExportEnabled = false;
        break;
    case 'bc':
      apiService = new BCAPIService();
      viewRenderer = new BCViewRenderer();
      isSubscriptionEnabled = true;
      isChildGtinSwitchEnabled = true;
      if (process.env.REACT_APP_MODE === 'test') {
        isExportEnabled = true;
        isTradeItemHierarchyModalEnabled = true;
      }
      break;
    case 'denner':
      apiService = new DennerAPIService();
      viewRenderer = new DennerViewRenderer();
      isSubscriptionEnabled = true;
      break;
    case 'aldi':
      apiService = new AldiAPIService();
      viewRenderer = new AldiViewRenderer();
      isSubscriptionEnabled = true;
      break;
    case 'markant-book':
      apiService = new MarkantBookAPIService();
      viewRenderer = new MarkantBookViewRenderer();
      break;
    default:
      break;
  }

  return {
    customer: source,
    apiService: apiService,
    viewRenderer: viewRenderer,
    isSubscriptionEnabled: isSubscriptionEnabled,
    isEuvinoSubscriptionEnabled: isEuvinoSubscriptionEnabled,
    isBasicAuthEnabled: isBasicAuthEnabled,
    isCicEnabled: isCicEnabled,
    isGdsnTradeItemEnabled: isGdsnTradeItemEnabled,
    isExportEnabled: isExportEnabled,
    isChildGtinSwitchEnabled: isChildGtinSwitchEnabled,
    isTradeItemHierarchyModalEnabled: isTradeItemHierarchyModalEnabled

  };
}


function getSourceBasedSwitch() {
  return (
    <CustomerContext.Provider value={getCurrentContext()}>
      <Routes>
        <Route path={defaultPath()} element={<ContentProviderRoute />} />
        <Route path='/help' element={<HelpRoute />} />
        <Route path='/help-en' element={<HelpEnRoute />} />
        <Route path="/login" element={<LoginRoute />} />
        <Route path='/acs-redirect' element={<AcsRoute />} />
        <Route path="/" element={<Navigate to={defaultPath()}/>} />
      </Routes>
    </CustomerContext.Provider>
  )
}

// loading component for suspense fallback
const Loader = () => (
  <CircularProgress color="inherit" />
);

root.render(
  <Suspense fallback={<Loader />}>
    <Router>
      <LocalizationProvider>
        {getSourceBasedSwitch()}
      </LocalizationProvider>
    </Router>
  </Suspense>
);
