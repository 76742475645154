/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette.jsx';
import SearchFilter from "./SearchFilter.jsx";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { translateKey } from '../../common/helpers/Common.js';
import CircularProgress from '@mui/material/CircularProgress';
import { getCountryNameWithCode } from '../../common/helpers/CountryCodeHelper.js';
import{ getCurrentLanguage, getNumCountryCode, getCookie} from '../../common/helpers/Common.js';


const ArticlePassRequest = ({appContext, t}) => {
  const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
	const [loading, setLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [targetMarketSearch, setTargetMarketSearch] = useState('');
	const [ekgSearch, setEkgSearch] = useState('');
	const [gtinSearch, setGtinSearch] = useState('');
	const [glnSearch, setGlnSearch] = useState('');
	const [deleteRecordTosterMessage, setDeleteRecordTosterMessage] = useState('');
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [validTargetMarkets, setValidTargetMarkets] = useState([]);

  useEffect(() => {
    let countryCode = getCookie('country_code')
    countryCode = getNumCountryCode(countryCode)
    countryCode = (countryCode === '') ? 'all' : countryCode
    setTargetMarketSearch(countryCode)
    getValidTargetMarkets()
  }, []);

  useEffect(() => {
    if (targetMarketSearch !== '') {
      getArticlePassRequest(true);
    }
  }, [targetMarketSearch, page, pageSize, sortModel]);

  const handleTargetMarketSearch = (targetMarket) => {
    setTargetMarketSearch(targetMarket)
  }

  const handleEkgSearch = (ekg) => {
    setEkgSearch(ekg)
  }

  const handleGtinSearch = (gtin) => {
    setGtinSearch(gtin)
  }

  const handleGlnSearch = (gln) => {
    setGlnSearch(gln)
  }

  const getArticlePassRequestWithFilter = () => {
    setPage(0)
    getArticlePassRequest(true)
  }

  const handleOnChange = (event) => {
    let fieldId = event.target.id;
    let value = event.target.value;

    switch (fieldId) {
      case 'ekgSearch':
        setEkgSearch(value);
        break;
      case 'gtinSearch':
        setGtinSearch(value);
        break;
      case 'glnSearch':
        setGlnSearch(value);
        break;
      case 'targetMarketSearch':
        setTargetMarketSearch(value);
        break;
      default:
        break;
    }
  }

  const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showDeleteButton: true, 
      showLoader: false
    }));
  };

  const updateStatus = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, status: status } : record
    )
    setRows(data)
  }

  const displayDeleteButton = (recordId, status) => {
    let data = rows.map((record, index) =>
      record.id === recordId ? { ...record, showDeleteButton: status } : record
    )
    setRows(data)
  }

  const renderStatus = (status) => {
    const prefix = 'markantDataRequestModal'
    switch (status) {
      case 'deleted':
        return translateKey('deleted', prefix)
      case 'found':
        return translateKey('found', prefix)
      case 'init':
        return translateKey('init', prefix)
      case 'not_a_base_unit':
        return translateKey('notABaseUnit', prefix) 
      default:
        return status
    }
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackBarOpen(false)
  };

  const getArticlePassRequest = async (useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

    try {
      let targetMarket = (targetMarketSearch !== 'all') ? targetMarketSearch : ''
      let params = {
        ekg: ekgSearch,
        gtin: gtinSearch,
        targetMarket: targetMarket,
        gln: glnSearch,
        pageNumber: page + 1,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection
      }

      const response = await appContext.apiService.getArticlePassRequest(useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
          setIsSearching(false)
          console.log(rows)
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsSearching(false)
    } finally {
      setLoading(false); 
    }
  };

  const deleteApassRequest = async (apassRequestId) => {
    displayDeleteButton(apassRequestId, false)
    await appContext.apiService
      .deleteApassRequest(apassRequestId)
      .then(res => {
        if (res.status === 200) {
          res.json().then(res => {
            setSnackBarOpen(true)
            setDeleteRecordTosterMessage(`${apassRequestId} - Deleted successfully`)
            updateStatus(apassRequestId, 'deleted')
          });
        } else {
          res.json().then(res => {
            console.log("Error in fetching article pass request");
            console.log(res);
          })
        }
      });
  }

  const getValidTargetMarkets = async () => {
    await appContext.apiService.getValidTargetMarketsForMarkant()
      .then(res => {
        if (res.ok) {
          res.json().then(res => {
            setValidTargetMarkets( res.data);
          });
        } else {
          res.json().then(res => {
            console.log(res);
          })
        }
      });
  }
	
	const columns = [
    { field: 'gln', headerName: 'GLN', flex: 0.13, filterable: false, hideable: false, headerClassName: 'header-bold' },
    { field: 'ekg', headerName: 'Ekg', flex: 0.11, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'targetMarket', 
      headerName: 'Target Market', 
      flex: 0.13, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{getCountryNameWithCode(params.row.targetMarket, language)}</span>
      )
    },
    { field: 'gtin', headerName: 'GTIN', flex: 0.13, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 0.1, 
      filterable: false,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        <span>{renderStatus(params.row.status)}</span>
      )
    },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.16, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.16, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'header-bold',
      renderCell: (params) => (
        params.row.status !== 'deleted' && ( params.row.showDeleteButton ?
          <Tooltip title="Delete">
              <IconButton
                  color="primary"
                  onClick={() => deleteApassRequest(params.row.id)}
                  size="large">
                  <DeleteIcon style={{ color: red[500], cursor: 'pointer' }} />
              </IconButton>
          </Tooltip>
          :
          <Tooltip title="Delete">
            <IconButton size="large">
              <CircularProgress size={20} style={{ color: palette.secondary.main }} thickness={4} />
            </IconButton>
          </Tooltip>
        )
      ),
    },
  ];

  return (
    <div style={{ height: 650, width: '100%' }}>
      <Snackbar open={snackBarOpen} autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
          <Alert onClose={handleSnackBarClose} severity="success">
              {deleteRecordTosterMessage}
          </Alert>
      </Snackbar>
      <SearchFilter isSearching = {isSearching} 
        handleTargetMarketSearch = {handleTargetMarketSearch} 
        handleEkgSearch = {handleEkgSearch} 
        handleGtinSearch = {handleGtinSearch} 
        handleGlnSearch = {handleGlnSearch} 
        targetMarketSearch = {targetMarketSearch} 
        gtinSearch = {gtinSearch} 
        glnSearch = {glnSearch} 
        ekgSearch = {ekgSearch} 
        validTargetMarkets = {validTargetMarkets}  
        submitFunction = {getArticlePassRequestWithFilter} 
        handleOnChange = {handleOnChange}
      />
      <div style={{paddingTop: "2rem"}}></div>
      <DataGrid
        disableColumnSelector
        loading={loading}
        rows={rows}
        columns={columns}
        paginationModel={{ page, pageSize }}
        pagination
        onPaginationModelChange={(newPaginationModel) => {
          setPage(newPaginationModel.page);
          setPageSize(newPaginationModel.pageSize);
        }}
        paginationMode="server"
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiCircularProgress-root': {
            color: palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnHeaders': {
            width: '99% !important',
          },
        }}
      />
    </div>
  );
};

export default ArticlePassRequest;