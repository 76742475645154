import React from "react";
import { CustomerContext } from "../common/CustomerContext.js";
import { withTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';

import '../assets/products.css';

import { euvinoClientList } from "../common/helpers/Common.js";


class EuvinoClientList extends React.Component {
  static contextType = CustomerContext;

  constructor(props) {
    super(props);
    this.state = {
      clientId: this.props.clientId,
    };
  }

  filterOptions() {
    const dropDownList = euvinoClientList();
    var filters = Object.entries(dropDownList).map(([key, value]) => ({"code": key, "name": value}));

    return filters.map((dt, i) => {
      return (
        <MenuItem label="Select a filter" value={dt.code} key={i} name={dt.name}>
          {dt.name}
        </MenuItem>
      );
    });
  }


  render() {
    return (
      <div style={{ position: "absolute", bottom: "0%", right: "5%", width: "100%", paddingLeft: 15 }}>
        <FormControl variant="outlined" style={{width: "100%"}}>
          <Select
            labelId="search-fields-label"
            id="search-fields-select-outlined"
            value={this.props.clientId}
            onChange={this.props.onFilterChange}
            disabled={this.props.disableClientList}
          >
            {this.filterOptions()}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withTranslation()(EuvinoClientList);
