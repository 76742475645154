
import React from "react";
import Button from '@mui/material/Button';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {getCountryNameWithCode} from '../../common/helpers/CountryCodeHelper.js';
import {palette} from '../../assets/Palette.jsx';
import Grid from '@mui/material/Grid';
import { isGtinValid, isGlnValid, isGpcValid } from '../../common/helpers/Validator.js'
import{getCurrentLanguage} from '../../common/helpers/Common.js';


class SearchFilter extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            targetMarketSearch: this.props.targetMarketSearch,
            gtinSearch: this.props.gtinSearch,
            glnSearch: this.props.glnSearch,
            gpcSearch: this.props.gpcSearch,
            validTargetMarkets: this.props.validTargetMarkets,
            isSearching: this.props.isSearching
        }
        
    }

    componentDidMount() {
        this.getValidTargetMarkets();
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForGdsnSubscriptions()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }


    shouldSubmitButtonBeEnabled() {
        return (isGtinValid(this.state.gtinSearch)
            && isGlnValid(this.state.glnSearch)
            && isGpcValid(this.state.gpcSearch))
    }

    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return <Grid container direction="row" justifyContent="space-evenly">
                    <Grid item>
                        <TextField
                            label={t('gdsnSubscriptionModal.targetMarket')}
                            value={this.props.targetMarketSearch}
                            onChange={this.props.handleTargetMarketSearch}
                            select variant="outlined" size="small"
                            style={{ width: '194px', borderColor: palette.secondary.main}}
                            disabled={this.props.isSearching}
                        >
                            {this.props.validTargetMarkets.map(tm => <MenuItem key={tm} value={tm}>{getCountryNameWithCode(tm, language)}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="glnSearch"
                            label={t('gdsnSubscriptionModal.supplierGln')}
                            value={this.props.glnSearch}
                            error={!isGlnValid(this.props.glnSearch)}
                            helperText={isGlnValid(this.props.glnSearch) ? '' : t('gdsnSubscriptionModal.glnValidationText')}
                            onChange={this.props.handleOnChange} variant="outlined" size="small"
                            inputProps={{ maxLength: 13 }}
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                    <Grid item>
                        <TextField id="gtinSearch"
                            label="GTIN"
                            value={this.props.gtinSearch}
                            error={!isGtinValid(this.props.gtinSearch)}
                            helperText={isGtinValid(this.props.gtinSearch) ? '' : t('gdsnSubscriptionModal.gtinValidationText')}
                            onChange={this.props.handleOnChange} variant="outlined" size="small"
                            inputProps={{ maxLength: 14 }}
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                    <Grid item>
                        <TextField id="gpcSearch"
                            label="GPC"
                            value={this.props.gpcSearch}
                            error={!isGpcValid(this.props.gpcSearch)}
                            helperText={isGpcValid(this.props.gpcSearch) ? '' : t('gdsnSubscriptionModal.gpcValidationText')}
                            onChange={this.props.handleOnChange} variant="outlined" size="small"
                            disabled={this.props.isSearching}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            color="primary"
                            disabled={!this.shouldSubmitButtonBeEnabled() ||this.props.isSearching}
                            onClick={this.props.submitFunction}
                            style={{ height: '40px' }}
                        >
                            {t('gdsnSubscriptionModal.search')}
                        </Button>
                    </Grid>
                </Grid>
    }
}
  
export default withTranslation()(SearchFilter);