import React from "react";
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Button from '@mui/material/Button';

import RateReviewIcon from '@mui/icons-material/RateReview';
import HistoryIcon from '@mui/icons-material/History';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import Tooltip from '@mui/material/Tooltip';

class ModalIcons extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tableModal: props.tableModal,
            toggleXMLModal: props.toggleXMLModal,
            treeView: props.treeView,
            imageModal: props.imageModal,
            documentModal: props.documentModal,
            disableDocumentButton: props.disableDocumentButton,
            disableImageButton: props.disableImageButton,
            cicModal: props.cicModal,
            tradeItemRecordsModal: props.tradeItemRecordsModal,   
            tradeItemHierarchyModal: props.tradeItemHierarchyModal,   
            tradeItemWithdrawalModal: props.tradeItemWithdrawalModal,
            isEuvino: props.isEuvino,   
            isLidl: props.isLidl,
            areOtherOrigions: props.areOtherOrigions,
            dataSource: props.dataSource
        }
    }
        
    buttonSendEmail() {
        return (
            <Tooltip title={this.props.t('productDetailsModal.sendEmail')}>
                        <IconButton
                            style={{ cursor: 'pointer', outline: 'none' }}
                            onClick={()=>this.props.setEmailSettings(this.props.t)}
                            size="large">
                            <EmailIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
        );
    }

    buttonTableModal() {
        let buttonColor = !this.props.tableModal ? "primary" : ""

        return (
            <Tooltip title={this.props.t('productDetailsModal.details')}>
                        <span>
                            <IconButton
                                disabled={this.props.tableModal}
                                style={{ outline: 'none' }}
                                onClick={this.props.toggleTableModal}
                                size="large">
                                <TableChartIcon color={buttonColor}/>
                            </IconButton>
                        </span>
                    </Tooltip>
        );      
    }   

    buttonImageModal() {
        let disable = ( this.props.disableImageButton ) ? true : this.props.imageModal

        return (
            <Tooltip title={this.props.t('productDetailsModal.images')}><span>
                    { disable ? (<IconButton
                        disabled={disable}
                        style={{ outline: 'none' }}
                        onClick={this.props.toggleImageModal}
                        size="large"><PhotoLibraryIcon /></IconButton>)
                    : (<IconButton
                        disabled={disable}
                        style={{ outline: 'none' }}
                        onClick={this.props.toggleImageModal}
                        size="large"><PhotoLibraryIcon color="primary" /></IconButton>)}
                    </span>
                </Tooltip>
        );
    }
          
    buttonDocumentModal() {
        let disable = ( this.props.disableDocumentButton ) ? true : this.props.documentModal
        
        return (
            <Tooltip title={this.props.t('productDetailsModal.documents')}><span>
                    { disable ? (<IconButton
                        disabled={disable}
                        style={{ outline: 'none' }}
                        onClick={this.props.toggleDocumentModal}
                        size="large"><DescriptionIcon /></IconButton>
                    ) : (<IconButton
                        disabled={disable}
                        style={{ outline: 'none' }}
                        onClick={this.props.toggleDocumentModal}
                        size="large"><DescriptionIcon color="primary" /></IconButton>)}
                    </span>
                </Tooltip>
        );
    }

    buttonDownloadXmlJson() {
        return <Tooltip title={this.props.t('productDetailsModal.download')}>
            <Button style={{ outline: 'none' }} aria-controls="downloadXmlMenu" aria-haspopup="true" onClick={this.props.openXmlDownloadDropdown} >
                <GetAppRoundedIcon color="primary"></GetAppRoundedIcon>
            </Button>
        </Tooltip> 
    }
    
    buttonTreeModal() {
        return (
            <Tooltip title={this.props.t('productDetailsModal.sourceData')}>
                        <IconButton style={{ outline: 'none' }} onClick={this.props.toggleTree} size="large">
                            {this.props.toggleXMLModal && this.props.treeView ? <AccountTreeIcon color="primary" /> : <CodeIcon color="primary" />}
                        </IconButton>
                    </Tooltip>
        );
    }    

    buttonCicModal() {
        return this.props.isCicEnabled ?
        <IconButton
            style={{ outline: 'none' }}
            onClick={this.props.toggleCicModal}
            size="large">
          {this.props.cicModal ? <RateReviewIcon  /> :
            <RateReviewIcon />}
        </IconButton>
        : null;
    }    

    buttonTradeItemRecordModal(){
        return this.props.isGdsnTradeItemEnabled ?
                    <IconButton
                        style={{ outline: 'none' }}
                        onClick={this.props.toggleTradeItemRecordsModal}
                        size="large">
                    {this.props.tradeItemRecordsModal ? <HistoryIcon color="primary" /> :
                        <HistoryIcon />}
                    </IconButton>
                    : null;
    }

    buttonTradeItemHierarchyModal(){  
        return this.props.isGdsnTradeItemEnabled ?
            <IconButton
                style={{ outline: 'none' }}
                onClick={this.props.toggleTradeItemHierarchyModal}
                size="large">
                {this.props.tradeItemHierarchyModal ? <ScatterPlotIcon color="primary" /> :
                <ScatterPlotIcon />}
            </IconButton>
            : null;
    }
      
    buttonTradeItemWithdrawalModal(){  
        return this.props.isGdsnTradeItemEnabled ?
            <IconButton
                style={{ outline: 'none' }}
                onClick={this.props.toggleTradeItemWithdrawalModal}
                size="large">
                {this.props.tradeItemWithdrawalModal ? <ReportProblemIcon color="primary" /> :
                <ReportProblemIcon />}
            </IconButton>
            : null;
    }

    render() {
        let source = process.env.REACT_APP_SOURCE;
        let dataSource = this.props.dataSource.toLowerCase()
        return <div style={{display:"flex"}}>
                    { source === 'schwarz' ?
                        ( 
                            <div> 
                                {this.buttonTableModal()} 
                                {this.buttonImageModal()} 
                                {this.buttonDocumentModal()}
                                {this.buttonDownloadXmlJson()}
                                { dataSource === 'markant' ? this.buttonSendEmail() : ''}
                                {this.buttonTreeModal()}
                            </div>
                        )
                        :(
                            <div> 
                                {this.buttonTableModal()} 
                                {this.buttonTreeModal()}
                                {this.buttonImageModal()} 
                                {this.buttonDownloadXmlJson()}
                            </div>
                        )
                    }
                    {this.buttonCicModal()}
                    {this.buttonTradeItemRecordModal()}
                    {this.buttonTradeItemHierarchyModal()}
                    {this.buttonTradeItemWithdrawalModal()}
        </div>
    }
}

export default ModalIcons;