
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from "react";
import Modal from 'react-modal';
import { Label } from "reactstrap";
import { withTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette';

const gpcModalStyle = {
    content: {
        top: '30%',
        left: '50%',
        right: '72%',
        marginRight: '-50%',
        transform: 'translate(-50%, -20%)',
        overlay: { zIndex: 1000 }
    }
};

class GPCModal extends React.Component {

    renderGpcClassification(t) {
        let gpcClassification = []
        if (this.props.toggleGPCModal) {
            gpcClassification.push(<h2 key={'h1'}>{t('searchResults.gpcClassification')}</h2>)
            gpcClassification.push(<h4 key={'h2'}>{t('searchResults.segment')}: </h4>)
            gpcClassification.push(<Button key={'segment'} style={{ outline: 'none', color: palette.text.dark, fontWeight: 'bold', fontSize: '0.8rem', textAlign: 'left', marginBottom: '1%' }} disableRipple color="primary" onClick={(e) => this.props.clickExampleData(e)}>{this.props.segment}</Button>)
            gpcClassification.push(<h4 key={'h3'}>{t('searchResults.family')}: </h4>)
            gpcClassification.push(<Button key={'family'} style={{ outline: 'none', color: palette.text.dark, fontWeight: 'bold', fontSize: '0.8rem', textAlign: 'left', marginBottom: '1%' }} disableRipple color="primary" onClick={(e) => this.props.clickExampleData(e)}>{this.props.family}</Button>)
            gpcClassification.push(<h4 key={'h4'}>{t('searchResults.class')}: </h4>)
            gpcClassification.push(<Button key={'class'} style={{ outline: 'none', color: palette.text.dark, fontWeight: 'bold', fontSize: '0.8rem', textAlign: 'left', marginBottom: '1%' }} disableRipple color="primary" onClick={(e) => this.props.clickExampleData(e)}>{this.props.class}</Button>)
            gpcClassification.push(<h4 key={'h5'}>{t('searchResults.brick')}: </h4>)
            gpcClassification.push(<Button key={'brick'} style={{ outline: 'none', color: palette.text.dark, fontWeight: 'bold', fontSize: '0.8rem', textAlign: 'left' }} disableRipple color="primary" onClick={(e) => this.props.clickExampleData(e)}>{this.props.brick}</Button>)
            gpcClassification.push(<br/>)
            gpcClassification.push(<Label key='gpcClassification label' style={{ color: palette.text.main, fontWeight: 'bold', fontSize: '0.8rem', marginTop: '2%' }}>{t('searchResults.gpcModalHelpText')}</Label>)
        }
        return gpcClassification
    }

    render() {
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.props.toggleGPCModal}
                toggle={this.props.toggleGPCModalSearch}
                style={gpcModalStyle}
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
                    <Tooltip title={t('searchResults.close')}>
                        <IconButton
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.toggleGPCModalSearch}
                            style={{ outline: 'none' }}
                            size="large">
                            <CloseRoundedIcon style={{ color: palette.warning.close }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    {this.renderGpcClassification(t)}
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(GPCModal);