import React from "react";
// import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {palette} from '../assets/Palette';
import {getBaseUrl} from '../common/helpers/Common'

class HelpEnSchwarz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        document.body.classList.add("white-content");
    }

    generateDocumentation() {
        let doc = []
        let baseUrl = getBaseUrl()
        let docUrl = baseUrl + '/schwarz-docs/'

        doc.push(<h3 key='h3'>Help on search API</h3>)
        
        doc.push(
          <a key="doc" href={docUrl} target="_blank" rel="noopener noreferrer"> 
            Techinical documentation search-API
          </a>
        );
        return doc;
    }

    render() {
        return (
            <>
                <div className="content">
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%' }}>
                                    <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" width='192' height='53' />
                                </div>
                            </CardHeader>
                            <CardBody style={{ marginTop: '2%' }}>
                                <div style={{ fontSize: '1rem' }}>
                                    <h2 style={{ color: palette.text.dark }}><b>Help for the article search in the Product Data Pool</b></h2>
                                    <h3 style={{ color: palette.text.dark }}><b>General information</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>What can be searched?</b></h4>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}>Price and listing data of articles from SAP</li>
                                        <li style={{ color: palette.text.main }}>Master data of articles from PIM</li>
                                        <li style={{ color: palette.text.main }}>Published master data from GDSN</li>
                                    </ul>

                                    <h4 style={{ color: palette.text.dark }}><b>How can I search?</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        The search is triggered by entering a single search term or several terms separated by spaces in the search field. The search is updated every few seconds or when the filter changes. Please note that the previous search query must always be loaded before others can follow.<br/><br/>
                                        The search takes into account all terms contained in the search field and searches all attributes of the available articles.<br/><br/>
                                        This means that it can happen that articles are listed in the hit list, but the search term is not directly visible there.<br/><br/>
                                        This means that in addition to the actual article names and manufacturer names, it is also possible to search for ingredients, for example.<br/><br/>
                                        
                                        In addition to entering a classic search term, the search also depends on the filter settings below it. The most efficient use of the search is as follows:<br/><br/> 

                                        <ol style={{ borderLeft: '0px' }}>
                                            <li style={{ color: palette.text.main }}>Set <b>search filter</b>, e.g. exact term or GTIN subsequently select PIM and Markant as data source. And search only for basic articles</li>
                                            <li style={{ color: palette.text.main }}>Another option is to use the <b>specific search</b>, as this narrows down the area to be searched and therefore makes the search faster.</li>
                                            <li style={{ color: palette.text.main }}>If the result is not specific enough after using steps 1 and 2, you can <b>filter the list of results.</b></li>
                                        </ol>

                                        Please note that the more specific your query, the better the results.<br/><br/>
                                    </p>
                                    
                                    <h3 style={{ color: palette.text.dark }}><b>Set search filter</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>Various settings can be made for the search:</b></h4><br/>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>Items per page</b><br/>Specifies how many entries are displayed in the search hit list. The more entries are selected, the more time may be needed to display the result.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Logical Operator</b><br/><b>And - </b>the entered search terms must ALL be found in one article.<br/>Entering the search terms "fish meat" with this option will only find articles that contain the term "fish" AND the term "meat" in descriptive attributes.<br/>
                                            <b>Or - </b>at least one of the search terms must be found in an article.<br/>Entering the search terms "fish meat" with this option will find articles containing either the term "fish" OR the term "meat" OR both terms in descriptive attributes.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Search for exact term</b><br/>If this option is active, the search terms will be found only in the exact spelling, otherwise incomplete spellings will also be found.<br/>Example: Entering the search term "012345" without this active option will also find articles with the spelling "0123456" or "123012345".</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Search in</b><br/>Restriction of the data set to be searched to a specific connected data supplier.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Target market</b><br />You also can restrict the target market.</li><br/>
                                        <li style={{ color: palette.text.main }}><b>Search scope</b><br />The data set includes not only basic articles but also packaging, such as cartons or pallets. With the active option "Show base items only", the packaging is excluded from the search result.</li>
                                    
                                    </ul><br/>
                                    
                                    <h3 style={{ color: palette.text.dark }}><b>Specific search</b></h3>
                                    <h4 style={{ color: palette.text.dark }}><b>Using attribute identifiers as search filters</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        With the help of attribute identifiers of the search index, searches can be restricted to specific attributes. The attribute identifier is placed in front of the search term as a prefix separated by a colon. Attention must be paid to the exact spelling (upper/lower case) of the attribute identifier.<br/><br/>
                                        Example: brandName:search term restricts the search of "search term" to the attribute "brand name", e.g. "brandName:Fanta" only finds articles that contain the term "Fanta" in the brand name.
                                        <br/><br/>
                                    </p>
                                                                        
                                    <h4 style={{ color: palette.text.dark }}><b>List of possible search filters:</b></h4>

                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>source:</b> (Data source, at the moment only "GDSN" possible)</li>
                                        <li style={{ color: palette.text.main }}><b>productName:</b> (Article description)</li>
                                        <li style={{ color: palette.text.main }}><b>productDescription:</b> (Article description)</li>
                                        <li style={{ color: palette.text.main }}><b>brandName:</b> (Brand name)</li>
                                        <li style={{ color: palette.text.main }}><b>manufacturer:</b> (Manufacturer/Supplier)</li>
                                        <li style={{ color: palette.text.main }}><b>targetMarket:</b> (Target market)</li>
                                        <li style={{ color: palette.text.main }}><b>gtin:</b> (GTIN of an article)</li>
                                        <li style={{ color: palette.text.main }}><b>gln:</b> (GLN of a manufacturer)</li>
                                        <li style={{ color: palette.text.main }}><b>gpc:</b> (GPC Code Block, Class family or Segment)</li>
                                    </ul>
                                    
                                    <h4 style={{ color: palette.text.dark }}><b>Filter the Result List:</b></h4>

                                    <p style={{ color: palette.text.main }}>
                                        Another option is to filter the list of results. Here you can filter by manufacturer and by GPCs.<br/><br/> 
                                        However, there are some peculiarities here.<br/><br/>
                                        If you have a hit list that is still too large, the selection of manufacturers and GPCs must be limited to 14 for display reasons.<br/><br/>  
                                        A small peculiarity of the Product Data Pool is that if you have more than 14 GPCs or manufacturers displayed and you deselect one of the GPCs or manufacturers, all other GPCs and manufacturers not displayed are also no longer selected. If you want to reset this, you have to move the switch in Front once to the left and again to the right.<br/><br/>
                                        The connection between GPC and manufacturer is an "and" connection, which means that if you have selected GPC 1 and manufacturer 1, then only articles from manufacturer 1 that have GPC 1 will be displayed.<br/><br/>
                                        All items where no GPC is maintained due to lack of data quality in the source systems will have GPC 999999999.<br/><br/>
                                        Please also note that if you search for individual manufacturers or GPCs and these attributes are not maintained for the article you want, this article cannot be displayed due to the "and" connection.<br/>

                                        <br/>{this.generateDocumentation()}
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </>
        );
    }
}
export default HelpEnSchwarz
