import React from "react";
import { withTranslation } from 'react-i18next';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { CardTitle, Label } from "reactstrap";
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

const ToggleButton = styled(MuiToggleButton)(({ selectedcolor }) => ({
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: selectedcolor,
        outline: 'none'
    },
    '&.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#022244',
    },
    '&:not(.Mui-selected)': {
        color: 'black',
        backgroundColor: '#D5D5D5',
        outline: 'none'
    }
}));

const buttonStyle = {
    marginRight: '20px', 
    border: "1px solid rgba(0, 0, 0, 0.12)", 
    borderRadius: '4px',
    fontSize: '0.8rem',
    padding: '6px',
    width: '210px',
    marginBottom: '1%'
}

const maxButtonsToShow = 15;

class FilterButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedManufacturerList: this.displayButtonList(this.props.manufacturerFilterList),
            manufacturerButtonList: this.displayButtonList(this.props.manufacturerFilterList),
            manufacturerFilterSwitch: true,

            selectedGPCList: this.displayButtonList(this.props.gpcFilterList),
            gpcButtonList: this.displayButtonList(this.props.gpcFilterList),
            gpcFilterSwitch: true,
        }
        this.handleManufacturer = this.handleManufacturer.bind(this);
    }

    componentWillReceiveProps(nextProps){        
        const { gpcFilterList } = nextProps
        const { languageChanged } = nextProps
        const { gpcSelectedFilterList } = nextProps
        this.setState({gpcButtonList : this.displayButtonList(gpcFilterList)})
        if (languageChanged){            
            this.setState({selectedGPCList : gpcSelectedFilterList})
        }                      
    }
    
    /* GPC Filter Handling */
    handleGPCButtonChange = (event, GPCButton) => {
        this.setState({ selectedGPCList: GPCButton }, () => {
            this.handleGPC(this.state.selectedGPCList)
        });
    }

    handleGPCSwitch = (event) => {
        const filterList = this.finalFilterList(this.state.gpcButtonList, this.props.gpcFilterList)
        const selectedList = ( this.state.gpcFilterSwitch ) ? [] : filterList
        this.handleGPC(selectedList)

        this.setState({gpcFilterSwitch: !this.state.gpcFilterSwitch})
    }

    handleGPC = (selectedList) => {
        this.setState({selectedGPCList: selectedList})
        
        let theFilterList = {}
        const finalManufacturerList = this.finalFilterList(this.state.selectedManufacturerList, this.props.manufacturerFilterList)
        const finalList = selectedList

        theFilterList['gpc'] = finalList
        theFilterList['manufacturer'] = finalManufacturerList

        this.props.handleGPCManufacturerFilterButtons(theFilterList)
    }

    /* Manufacturer Filter Handling */
    handleManufacturerButtonChange = (event, manufacturerButton) => {
        this.setState({ selectedManufacturerList: manufacturerButton }, () => {
            this.handleManufacturer(this.state.selectedManufacturerList)
        });
    }

    handleManufacturerSwitch = (event) => {
        const filterList = this.finalFilterList(this.state.manufacturerButtonList, this.props.manufacturerFilterList)
        const selectedList = ( this.state.manufacturerFilterSwitch ) ? [] : filterList

        this.handleManufacturer(selectedList)

        this.setState({manufacturerFilterSwitch: !this.state.manufacturerFilterSwitch})
    }

    handleManufacturer = (selectedList) => {
        this.setState({selectedManufacturerList: selectedList})
        
        let theFilterList = {}
        const finalGPCList = this.finalFilterList(this.state.selectedGPCList, this.props.gpcFilterList)
        const finalList = selectedList

        theFilterList['gpc'] = finalGPCList
        theFilterList['manufacturer'] = finalList

        this.props.handleGPCManufacturerFilterButtons(theFilterList)
    }

    /* Other functions */
    finalFilterList = (selectedList, filterList) => {
        const remainingList = this.remainingButtonList(filterList)
        return selectedList.concat(remainingList)
    }

    remainingButtonList = (buttonList) => {
        return buttonList.slice(maxButtonsToShow, buttonList.length)
    }

    displayButtonList = (buttonList) => {
        return buttonList.slice(0, maxButtonsToShow)
    }

    toggleButtonList = (manufacturerFilterList) => {
        let content = [];
        let shortString = '';

        for (let i = 0; i < maxButtonsToShow; i++) {
            shortString = manufacturerFilterList[i]
            if (typeof shortString !== 'undefined' && shortString.length > 20) {
                shortString = shortString.substring(0, 15) + '...'
            }

            if (typeof shortString !== 'undefined' && shortString.length > 0) {
                content.push(<ToggleButton key={i} value={manufacturerFilterList[i]} aria-label="left aligned" selectedcolor="#002857" style={buttonStyle}>
                   {shortString}
                </ToggleButton>);
            }
        }
        return content;
    }

    render() {
        const { t } = this.props;
        const buttonHelpText = t('searchResults.filterMaxButtonsHelpText')
        
        return <div>
            <CardTitle tag="h6" key='filterTitle' style={{ color: '#939393', fontWeight: 'bold', fontSize: '1.0rem' }}>{t('searchResults.extraFilters')}:</CardTitle>
            <Divider key='divider1' style={{ marginBottom: '0.5%' }} />
            <div key='gpcDiv' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div key='gpcSwitchDiv' style={{ display: 'flex', flexDirection: 'column', width: '8%' }}>
                    <Label key='gpcLabel' style={{ color: '#939393', fontWeight: 'bold', fontSize: '0.9rem', marginRight: '2%' }}>GPC</Label>
                    <Switch key='gpcSwitch' size="small" checked={this.state.gpcFilterSwitch} color="primary" onChange={this.handleGPCSwitch} />
                </div>
                <div key='gpcButtonDiv' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div>
                        <ToggleButtonGroup
                            value={this.state.selectedGPCList}
                            onChange={this.handleGPCButtonChange}
                            aria-label="text gpclist"
                            style={{display: 'contents'}}
                            >
                            {this.toggleButtonList(this.state.gpcButtonList)}
                        </ToggleButtonGroup>
                    </div>
                    { this.state.gpcButtonList.length === maxButtonsToShow &&
                        <Label key={'maxLimitLabelGPC'}>
                            {buttonHelpText.replace('{maxButtonsToShow}', maxButtonsToShow).replace('{filterType}', t('searchResults.filterButtonHelpTextTypeGpc'))}
                        </Label>
                    }
                </div>    
            </div>
            <Divider key='divider2' style={{ marginTop: '0.3%', marginBottom: '0.5%' }} />
            <div key='manufacturerDiv' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div key='manufacturerSwitchDiv' style={{ display: 'flex', flexDirection: 'column', width: '8%' }}>
                    <Label key='gpcLabel' style={{ color: '#939393', fontWeight: 'bold', fontSize: '0.9rem', marginRight: '2%' }}>{t('searchResults.manufacturer')}</Label>
                    <Switch key='manufacturerSwitch' size="small" checked={this.state.manufacturerFilterSwitch} color="primary" onChange={this.handleManufacturerSwitch}/>
                </div>
                <div key='manufacturerButtonDiv' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div>
                        <ToggleButtonGroup
                            value={this.state.selectedManufacturerList}
                            onChange={this.handleManufacturerButtonChange}
                            aria-label="text manufacturer"
                            style={{display: 'contents'}}
                            >
                            {this.toggleButtonList(this.state.manufacturerButtonList)}
                        </ToggleButtonGroup>
                    </div>
                    { this.state.manufacturerButtonList.length === maxButtonsToShow &&
                        <Label key={'maxLimitLabelManufacturer'}>
                            {buttonHelpText.replace('{maxButtonsToShow}', maxButtonsToShow).replace('{filterType}', t('searchResults.filterButtonHelpTextTypeManufacturer'))}
                        </Label>
                    }          
                </div>
            </div>
            <Divider key='divider3' style={{ marginTop: '0.3%', marginBottom: '0.5%' }} />
        </div>
    }

}

export default withTranslation()(FilterButtons);